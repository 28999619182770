import React, { useContext } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import { LocalizationContext } from "../context/LocalizationContext"

const ThanksPage = ({ data }) => {
  const { translate } = useContext(LocalizationContext)
  return (
    <Layout overlayHeader={true}>
      <SEO title={translate("thanks.thanks")} />
      <Hero
        title={translate("thanks.thanks")}
        subTitle={translate("thanks.contact")}
        image={data.hero}
      />
    </Layout>
  )
}

export default ThanksPage

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "husky.jpg" }) {
      colors {
        ...GatsbyImageColors
      }
      childImageSharp {
        fluid(maxWidth: 1440, quality: 60) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
